import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout";
import Slider from "react-slick";
import SliderSettings from "../components/SliderSettings";
import Banner from "../components/Banner";
import TitleDescriptionBlock from "../components/TitleDescriptionBlock";
import IconTexts from "../components/IconTexts";
import Separator from "../components/Separator";
import ImageTextBox from "../components/ImageTextBox";
import Gallery from "../components/Gallery";
import {Contact} from "../components/Contact";
import SEO from "../components/SEO";

class TemplateCompany extends Component {

    render() {
        const currentPage = this.props.data.wordpressPage;
        return (
            <Layout>
                <SEO title={currentPage.yoast_meta.yoast_wpseo_title}
                     description={currentPage.yoast_meta.yoast_wpseo_metadesc}
                />
                <Slider {...SliderSettings}>
                    {currentPage.acf.slider.map((slide, index) => {
                        return (
                            <Banner
                                key={slide.default.title + "_" + index}
                                title={slide.default.title}
                                description={slide.default.description}
                                image={slide.default.image}
                            />
                        );
                    })}
                </Slider>

                <TitleDescriptionBlock title={currentPage.acf.intro.title} description={currentPage.acf.intro.description} />

                <Contact contact={currentPage.acf.contact} />
            </Layout>
        )
    }
}

export default TemplateCompany;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            date(formatString: "MMMM DD, YYYY")
            ...seo
            acf {
                ...sliderFragment
                ...introFragment
                ...contact
            }
        }
    }
`