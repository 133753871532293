import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@material-ui/core/Button';
import Img from "gatsby-image"
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Image from "./Image";
import './ImageTextBox.css';

const ImageTextBox = ({ title, subtitle, description, image, imageLeft, url, urlText, isButtonVisible, disableClickHandler, clickHandler }) => {
    return (
        <div className="imageTextBox">
            {imageLeft ? <Paper className="image-text-box-container">
                    <Grid container>
                        <Grid item lg={6} md={6} xs={12}>
                            {
                                disableClickHandler ? <Image image={image} />
                                    :   <Link className="image-text-box-url" to={url}>
                                            <Image image={image} />
                                    </Link>
                            }

                        </Grid>
                        <Grid item lg={6} md={6} xs={12} className="image-text-box-content">
                            <div className="image-text-box-subtitle">{subtitle}</div>
                            {
                                disableClickHandler ? <h1 onClick={clickHandler} className="image-text-box-title">{title}</h1>
                                    :   <Link className="image-text-box-url" to={url}>
                                        <h1 className="image-text-box-title">{title}</h1>
                                    </Link>
                            }

                            <div className="image-text-box-description" dangerouslySetInnerHTML={{__html: description}} />
                            <br/>
                            {
                                isButtonVisible && <Link className="image-text-box-url" to={url}>
                                    <Button variant="contained" size="medium" color="primary">
                                        {urlText}
                                    </Button>
                                </Link>
                            }
                        </Grid>
                    </Grid>
                </Paper>
                : <Paper className="image-text-box-container">
                    <Grid container>
                        <Hidden mdUp>
                            <Grid item xs={12} md={8}>
                                {
                                    disableClickHandler ? <Image image={image} />
                                        :   <Link to={url}>
                                                <Image image={image} />
                                        </Link>
                                }
                            </Grid>
                        </Hidden>
                        <Grid item lg={6} md={6} xs={12} className="image-text-box-content">
                            <div className="image-text-box-subtitle">{subtitle}</div>
                            {
                                disableClickHandler ? <h1 onClick={clickHandler} className="image-text-box-title">{title}</h1>
                                    :   <Link className="image-text-box-url" to={url}>
                                        <h1 className="image-text-box-title">{title}</h1>
                                    </Link>
                            }
                            <div className="image-text-box-description" dangerouslySetInnerHTML={{__html: description}} />
                            <br/>
                            {
                                isButtonVisible && <Link to={url}>
                                    <Button variant="contained" size="medium" color="primary">
                                        {urlText}
                                    </Button>
                                </Link>
                            }
                        </Grid>
                        <Hidden smDown>
                            <Grid item lg={6} md={6}>
                                {
                                    disableClickHandler ? <Image image={image} />
                                        :   <Link to={url}>
                                                <Image image={image} />
                                        </Link>
                                }
                            </Grid>
                        </Hidden>
                    </Grid>
                </Paper>
            }
        </div>
    );
}

ImageTextBox.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    imageLeft: PropTypes.number,
    url: PropTypes.string,
    urlText: PropTypes.string,
    isButtonVisible: PropTypes.bool,
    clickHandler: PropTypes.func,
    disableClickHandler: PropTypes.bool
};

ImageTextBox.defaultProps = {
    title: ``,
    subTitle: ``,
    image: ``,
    imageLeft: true,
    description: ``,
    url: null,
    urlText: `Mehr erfahren`,
    isButtonVisible: false,
    clickHandler: null,
    disableClickHandler: true
};

export default ImageTextBox